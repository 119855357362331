
// Vue reactivity
import { computed, ref } from "vue";

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
        IonGrid, IonRow, IonCol, IonSpinner, IonButtons, IonBackButton } from "@ionic/vue";
import ProductCard from "@/components/ProductCard.vue";

import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: "UserLikedItemListPage",
  components: {
    IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
    IonGrid, IonRow, IonCol, IonSpinner, IonButtons, IonBackButton, ProductCard,
  },
  setup() {
    const store = useStore();
    // 1. declare state variables (ref to make them reactive)
    const loading = ref(false);
    const products = computed(() => store.getters.userLikedItems);

    // methods or filters
    const { t } = useI18n();

    // 3. return variables & methods to be used in template HTML
    return {
      t,

      // state variables
      loading, products,
    };
  },
};
